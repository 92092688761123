import { EProductClimax, ICreateProduct, IProductFormValues } from "../types";

export const mapProductToCreate = (values: IProductFormValues): ICreateProduct => {
  return {
    name: values.name,
    categoryName: values.categoryName,
    climax: values.climax === EProductClimax.None ? null : values.climax,
    external: values.external,
    metadata: values.metadata
  }
}