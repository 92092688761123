import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EOrderPartOperations, IOrderPart } from "../types";
import { Body2, Button2 } from "../../../components/Typography";
import { OrderStatus } from "../OrderStatus";
import { getOrderPartOperations, manualDelivery } from "../api";
import { LMIconButton } from "../../../components/LMIconButton";
import { IconOperationManualDelivery } from "../../../assets";
import { Tooltip } from "antd";
import { useAppNotifications } from "../../../components/LMNotifications";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import { refreshOrder } from "../slice";
import { AbilityContext } from "../../casl";
import styles from "./OrderParts.module.css";

interface Props {
  part: IOrderPart;
}

export const OrderPartItem: FC<Props> = ({ part }) => {
  const [operations, setOperations] = useState<EOrderPartOperations[]>([]);
  const ability = useContext(AbilityContext);
  const dispatch = useDispatch<AppDispatch>();
  const { contextHolder, success, error } = useAppNotifications();
  const { t } = useTranslation("order");

  const fetchOperations = useCallback(async () => {
    const response = await getOrderPartOperations(part.id);
    setOperations(response.data);
  }, [part]);

  useEffect(() => {
    if (!ability.can("update", "orders")) return;
    fetchOperations();
  }, [fetchOperations, ability]);

  const handleManualDelivery = async () => {
    try {
      await manualDelivery(part.id);
      await dispatch(refreshOrder({ orderId: part.orderId })).unwrap();
      success(t("deliverySuccess"));
    } catch {
      error(t("operationFail"));
    }
  };

  return (
    <>
      {contextHolder}
      <div className={styles.header}>
        <div className={styles["header-left"]}>
          <Body2>{part.id}</Body2>
          <OrderStatus status={part.status} />
        </div>
        <div className={styles["header-right"]}>
          <Body2 className={styles["header-products-label"]}>
            {t("products")}:
          </Body2>
          <Button2>{part.products.length}</Button2>
          <div className={styles.operations}>
            {operations.map((operation) => {
              if (operation === EOrderPartOperations.ManualDelivery) {
                return (
                  <Tooltip title={t("ManualDelivery")}>
                    <LMIconButton
                      onClick={handleManualDelivery}
                      className={styles["operation-icon-button"]}
                    >
                      <IconOperationManualDelivery />
                    </LMIconButton>
                  </Tooltip>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </>
  );
};
