import { FC, useCallback, useContext, useEffect, useState } from "react";
import { format } from "date-fns";
import { Divider } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Body2 } from "../../../components/Typography";
import { InfoCard } from "../InfoCard";
import { EOrderOperations, IOrder } from "../types";
import { DATE_TIME_NO_SECONDS_FORMAT } from "../../../app/constants";
import { OrderParts } from "../OrderParts";
import { LMNewButton } from "../../../components/LMNewButton";
import { IconCancelOrder, IconProcessOrder } from "../../../assets";
import { cancelOrder, getOrderOperations, queueOrder } from "../api";
import { AppDispatch } from "../../../app/store";
import { refreshOrder } from "../slice";
import { useAppNotifications } from "../../../components/LMNotifications";
import { AbilityContext } from "../../casl";
import styles from "./OrderInfo.module.css";

interface Props {
  order: IOrder;
}

export const OrderInfo: FC<Props> = ({ order }) => {
  const { t } = useTranslation("order");
  const ability = useContext(AbilityContext);
  const [operations, setOperations] = useState<EOrderOperations[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const { contextHolder, success, error } = useAppNotifications();

  const handleCancelOrder = async () => {
    try {
      await cancelOrder(order.id);
      await dispatch(refreshOrder({ orderId: order.id })).unwrap();
      success(t("cancelSuccess"));
    } catch {
      error(t("operationFail"));
    }
  };

  const handleQueueOrder = async () => {
    try {
      await queueOrder(order.id);
      await dispatch(refreshOrder({ orderId: order.id })).unwrap();
      success(t("queueSuccess"));
    } catch {
      error(t("operationFail"));
    }
  };

  const fetchOrderOperations = useCallback(async () => {
    const operations = await getOrderOperations(order.id);
    setOperations(operations.data);
  }, [order]);

  useEffect(() => {
    if (!ability.can("update", "orders")) return;
    fetchOrderOperations();
  }, [fetchOrderOperations, ability]);

  const hasOperation = (operation: EOrderOperations) => {
    return operations.some((o) => o === operation);
  };

  return (
    <div className={styles.container}>
      {contextHolder}
      <InfoCard
        title={`${t("id")}: ${order.id}`}
        status={order.status}
        iconType="order"
        noBottomRadius={!!(order.parts && order.parts.length > 0)}
        action={
          <div className={styles.actions}>
            {hasOperation(EOrderOperations.Queue) && (
              <LMNewButton
                color="default"
                className={styles["process-button"]}
                onClick={handleQueueOrder}
              >
                {t("process")}
                <IconProcessOrder />
              </LMNewButton>
            )}
            {hasOperation(EOrderOperations.Cancel) && (
              <LMNewButton
                color="danger"
                className={styles["cancel-button"]}
                onClick={handleCancelOrder}
              >
                {t("cancel")}
                <IconCancelOrder />
              </LMNewButton>
            )}
          </div>
        }
      >
        <Body2>
          <span className={styles.light}>
            {format(new Date(order.createdOn), DATE_TIME_NO_SECONDS_FORMAT)}
          </span>
          <Divider type="vertical" className={styles.divider} />
          <span className={styles.light}>{t("packs")}: </span>
          {order.packsCount}
          <Divider type="vertical" className={styles.divider} />
          <span className={styles.light}>{t("code")}: </span>
          {order.code}
        </Body2>
      </InfoCard>
      {order.parts && <OrderParts parts={order.parts} />}
    </div>
  );
};
